<script setup lang="ts"></script>

<template>
    <svg
        width="23"
        height="18"
        viewBox="0 0 23 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.44074 15.8682C6.30906 15.6418 6.01822 15.5632 5.79369 15.6949L5.6838 15.7583V15.6314C5.6838 15.3699 5.47159 15.1577 5.21012 15.1577C4.94865 15.1577 4.73644 15.3699 4.73644 15.6314V15.7583L4.62655 15.6949C4.39918 15.5632 4.11023 15.6418 3.9795 15.8682C3.84876 16.0947 3.92645 16.3845 4.15287 16.5153L4.26276 16.5788L4.15287 16.6422C3.92645 16.773 3.84876 17.0629 3.9795 17.2893C4.06666 17.4409 4.22581 17.5261 4.38971 17.5261C4.47023 17.5261 4.5517 17.5062 4.62655 17.4626L4.73644 17.3992V17.5261C4.73644 17.7876 4.94865 17.9998 5.21012 17.9998C5.47159 17.9998 5.6838 17.7876 5.6838 17.5261V17.3992L5.79369 17.4626C5.86854 17.5062 5.95001 17.5261 6.03054 17.5261C6.19443 17.5261 6.35359 17.4409 6.44169 17.2893C6.57243 17.0629 6.49474 16.773 6.26832 16.6422L6.15748 16.5788L6.26738 16.5153C6.49379 16.3845 6.57148 16.0947 6.44074 15.8682Z"
            fill="currentColor"
        />
        <path
            d="M14.0198 15.8682C13.8882 15.6418 13.5983 15.5632 13.3728 15.6949L13.2629 15.7583V15.6314C13.2629 15.3699 13.0507 15.1577 12.7892 15.1577C12.5278 15.1577 12.3155 15.3699 12.3155 15.6314V15.7583L12.2056 15.6949C11.9792 15.5632 11.6893 15.6418 11.5586 15.8682C11.4279 16.0947 11.5055 16.3845 11.732 16.5153L11.8419 16.5788L11.732 16.6422C11.5055 16.773 11.4279 17.0629 11.5586 17.2893C11.6467 17.4409 11.8059 17.5261 11.9698 17.5261C12.0503 17.5261 12.1318 17.5062 12.2066 17.4626L12.3155 17.3992V17.5261C12.3155 17.7876 12.5278 17.9998 12.7892 17.9998C13.0507 17.9998 13.2629 17.7876 13.2629 17.5261V17.3992L13.3728 17.4626C13.4476 17.5062 13.5291 17.5261 13.6096 17.5261C13.7735 17.5261 13.9327 17.4409 14.0208 17.2893C14.1515 17.0629 14.0738 16.773 13.8474 16.6422L13.7366 16.5788L13.8465 16.5153C14.0729 16.3845 14.1506 16.0947 14.0198 15.8682Z"
            fill="currentColor"
        />
        <path
            d="M11.7151 14.6843C11.879 14.6843 12.0382 14.5991 12.1263 14.4475C12.257 14.2211 12.1793 13.9312 11.9529 13.8004L11.8421 13.737L11.9519 13.6735C12.1784 13.5428 12.256 13.2529 12.1253 13.0264C11.9936 12.8 11.7037 12.7223 11.4783 12.8531L11.3684 12.9165V12.7896C11.3684 12.5281 11.1562 12.3159 10.8947 12.3159C10.6332 12.3159 10.421 12.5281 10.421 12.7896V12.9165L10.3111 12.8531C10.0847 12.7223 9.7948 12.8 9.66407 13.0264C9.53333 13.2529 9.61102 13.5428 9.83744 13.6735L9.94733 13.737L9.83744 13.8004C9.61102 13.9312 9.53333 14.2211 9.66407 14.4475C9.75217 14.5991 9.91133 14.6843 10.0752 14.6843C10.1557 14.6843 10.2372 14.6644 10.3121 14.6208L10.421 14.5574V14.6843C10.421 14.9458 10.6332 15.158 10.8947 15.158C11.1562 15.158 11.3684 14.9458 11.3684 14.6843V14.5574L11.4783 14.6208C11.5531 14.6644 11.6346 14.6843 11.7151 14.6843Z"
            fill="currentColor"
        />
        <path
            d="M7.31786 11.4187C7.08291 11.3021 6.79965 11.3959 6.68218 11.6309L5.73482 13.5256C5.61734 13.7596 5.71303 14.0438 5.94703 14.1613C6.01429 14.1944 6.08629 14.2105 6.15829 14.2105C6.3326 14.2105 6.49934 14.1148 6.58271 13.9491L7.53007 12.0543C7.64659 11.8203 7.55186 11.5352 7.31786 11.4187Z"
            fill="currentColor"
        />
        <path
            d="M9.21239 15.2078C8.97744 15.0922 8.69418 15.1851 8.57671 15.42L7.62935 17.3147C7.51188 17.5487 7.60756 17.8329 7.84156 17.9504C7.90882 17.9836 7.98082 17.9997 8.05282 17.9997C8.22713 17.9997 8.39387 17.904 8.47724 17.7382L9.4246 15.8435C9.54112 15.6095 9.44639 15.3243 9.21239 15.2078Z"
            fill="currentColor"
        />
        <path
            d="M17.5262 3.78944C17.1349 3.78944 16.7361 3.8425 16.3155 3.94955C15.1199 1.53757 12.6653 0 9.94729 0C6.48184 0 3.52797 2.51524 2.94913 5.90111C1.19746 6.46669 0 8.08667 0 9.94729C0 12.2977 1.91272 14.2104 4.28302 14.2104C4.28586 14.2104 4.28965 14.2104 4.29249 14.2104C4.55586 14.2284 4.77565 14.002 4.77565 13.7367C4.77565 13.6951 4.76996 13.6543 4.75954 13.6155C4.77091 13.4156 4.81165 13.2498 4.88554 13.102L5.8329 11.2073C6.17395 10.5242 7.04931 10.2277 7.73994 10.5716C8.19088 10.7971 8.47983 11.2376 8.51488 11.7492C8.52625 11.9197 8.62951 12.0713 8.78393 12.1442C8.9374 12.2172 9.1193 12.202 9.26045 12.1044C9.39782 12.0078 9.54371 11.9377 9.69434 11.896C9.77203 11.8742 9.84213 11.8335 9.89992 11.7776C10.4352 11.2518 11.3532 11.2499 11.8894 11.7776C11.9472 11.8344 12.0173 11.8752 12.095 11.896C12.4294 11.9889 12.7193 12.2049 12.9097 12.5052C13.0016 12.6492 13.1569 12.7373 13.3341 12.724C13.5046 12.7155 13.6572 12.6151 13.7329 12.4625L14.361 11.2073C14.7011 10.5242 15.5784 10.2277 16.2681 10.5716C16.9691 10.9221 17.2543 11.7776 16.9038 12.4786L16.3799 13.5255C16.306 13.6723 16.3145 13.8466 16.4007 13.9859C16.4869 14.1252 16.6394 14.2104 16.8033 14.2104H17.5262C20.3995 14.2104 22.7367 11.8733 22.7367 8.99993C22.7367 6.12658 20.3995 3.78944 17.5262 3.78944Z"
            fill="currentColor"
        />
        <path
            d="M15.8442 11.4187C15.6102 11.3021 15.326 11.3959 15.2085 11.6309L14.2612 13.5256C14.1437 13.7596 14.2394 14.0438 14.4734 14.1613C14.5416 14.1954 14.6136 14.2115 14.6847 14.2115C14.859 14.2115 15.0257 14.1158 15.1091 13.95L16.0564 12.0553C16.173 11.8203 16.0782 11.5352 15.8442 11.4187Z"
            fill="currentColor"
        />
    </svg>
</template>
