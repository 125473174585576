<script setup lang="ts">
const canvasEl = ref<HTMLCanvasElement | null>(null);

const setup = () => {
    // const NUMBER_OF_SNOWFLAKES = 100;
    // const MAX_SNOWFLAKE_SIZE = 5;
    // const MAX_SNOWFLAKE_SPEED = -3;
    // const SNOWFLAKE_COLOR = "#ddd";
    // const snowflakes: any[] = [];
    // const canvas = document.createElement("canvas");
    // canvas.style.position = "absolute";
    // canvas.style.top = "0px";
    // canvas.style.pointerEvents = "none";
    // canvas.style.zIndex = "1000";
    // canvas.width = window.innerWidth;
    // canvas.height = window.innerHeight;
    // canvasEl.value = canvas;
    // document.body.appendChild(canvas);
    // const ctx = canvas.getContext("2d");
    // if (!ctx) return;
    // const createSnowflake = () => ({
    //     x: Math.random() * canvas.width,
    //     y: Math.random() * canvas.height,
    //     radius: Math.floor(Math.random() * MAX_SNOWFLAKE_SIZE),
    //     color: SNOWFLAKE_COLOR,
    //     speed: Math.random() * MAX_SNOWFLAKE_SPEED + 3,
    //     sway: Math.random() - 0.5
    // });
    // const drawSnowflake = (snowflake: any) => {
    //     ctx.beginPath();
    //     ctx.arc(snowflake.x, snowflake.y, snowflake.radius, 0, 2 * Math.PI);
    //     ctx.fillStyle = snowflake.color;
    //     ctx.fill();
    //     ctx.closePath();
    // };
    // const updateSnowflake = (snowflake: any) => {
    //     snowflake.y += snowflake.speed;
    //     snowflake.x += snowflake.sway;
    //     if (snowflake.y > canvas.height) {
    //         Object.assign(snowflake, createSnowflake());
    //     }
    //     if (snowflake.x > canvas.width) {
    //         Object.assign(snowflake, createSnowflake());
    //     }
    // };
    // const animate = () => {
    //     const ctx = canvas.getContext("2d");
    //     if (!ctx) return;
    //     ctx.clearRect(0, 0, canvas.width, canvas.height);
    //     snowflakes.forEach(snowflake => {
    //         drawSnowflake(snowflake);
    //         updateSnowflake(snowflake);
    //     });
    //     requestAnimationFrame(animate);
    // };
    // for (let i = 0; i < NUMBER_OF_SNOWFLAKES; i++) {
    //     snowflakes.push(createSnowflake());
    // }
    // window.addEventListener("resize", () => {
    //     canvas.width = window.innerWidth;
    //     canvas.height = window.innerHeight;
    // });
    // window.addEventListener("scroll", () => {
    //     canvas.style.top = window.scrollY + "px";
    // });
    // animate();
    // setInterval(animate, 1000 / 60);
};

onUnmounted(() => {
    if (canvasEl.value) {
        document.body.removeChild(canvasEl.value);
    }
});

defineExpose({ setup });
</script>

<template>
    <div class="snow"></div>
</template>

<style lang="scss">
$d: 10; // density
$w: 900; // grid size
$s: 8s; // speed

// Generate the snow image using radial gradients
$grad: ();
@for $i from 0 to $d {
    $v: random(4) + 2;
    $a: random(5) * 0.1 + 0.5;
    $grad: $grad,
        radial-gradient(
            $v +
                px
                $v +
                px
                at
                (random($w - $v * 2) + $v) +
                px
                (random($w - $v * 2) + $v) +
                px,
            rgba(255, 255, 255, $a) 50%,
            rgba(0, 0, 0, 0)
        ) !global;
}

// The CSS
.snow,
.snow:before,
.snow:after {
    position: fixed;
    pointer-events: none;
    z-index: 0;
    top: -$w + px;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: $grad;
    background-size: $w + px $w + px;
    animation: snow $s linear infinite;
    content: "";
}
.snow:after {
    margin-left: -$w/3 + px;
    opacity: 0.4;
    animation-duration: $s * 2;
    animation-direction: reverse;
    filter: blur(3px);
}
.snow:before {
    animation-duration: $s * 3;
    animation-direction: reverse;
    margin-left: -$w/2 + px;
    opacity: 0.65;
    filter: blur(1.5px);
}
@keyframes snow {
    to {
        transform: translateY($w + px);
    }
}
</style>
